const $ = require('jquery')
window.jQuery = $

import AOS from 'aos'
import axios from 'axios'
import _ from 'lodash'
require('foundation-sites')
require('slick-carousel')

function solutionSlider() {
    if(!$('.solutions-slider .sliderwrap').is('.slick-slider')){
        $('.solutions-slider .sliderwrap').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            appendArrows: $('.solutions-slider .arrows'),
            prevArrow: '<i class="icon icon-arrow-left"></i>',
            nextArrow: '<i class="icon icon-arrow-right"></i>',
        })
    }
}

$(function() {
    $(document).foundation()

    AOS.init()
    window.addEventListener('load', AOS.refresh)

    if(window.location.hash){
        var targetDiv = $(window.location.hash)

        $('html, body').animate({
            scrollTop: $(targetDiv).offset().top - $('header').height()
        }, 1000)
    }

    $('#hamburger, .menu-backdrop').on('click', function(){
        $('#hamburger, body, header').toggleClass('nav-open')
	});

    if($('#contact').length){
        $('input.is-invalid-input').on('click', function() {
            $(this).removeClass('is-invalid-input')
            $(this).next().slideUp()
        })
    }

    $(window).on('scroll', function () {
        var scroll = $(window).scrollTop()
        if (scroll > $('header').outerHeight()) {
            $('header').addClass("scrolling")
        } else {
            $('header').removeClass("scrolling")
        }
    })

    $('ul.faqs li .question').on('click', function () {
        if($(this).closest('li').is('.active')){
            $(this).closest('li').removeClass('active').find('.answer').slideUp()
            return
        }

        $('ul.faqs li').removeClass('active').find('.answer').slideUp()

        $(this).closest('li').toggleClass('active')
        $(this).closest('li').find('.answer').slideDown()
    })

    // Home banner slider
    if($('#banner.home #slider .slides').length){
        $('#banner.home #slider .slides').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            autoplay: true,
            nextArrow: '<i class="icon icon-arrow-right"></i>',
            prevArrow: '<i class="icon icon-arrow-left"></i>',

            responsive: [{
                breakpoint: 640,
                settings: {
                    arrows: true,
                    appendArrows: $('#banner.home #slidernav .mobile-arrows')
                }
            }]
        })

        $('#banner.home #slider .slides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            $('#banner.home #slidernav li a').removeClass('is-active')
            $('#banner.home #slidernav li:nth-child('+(nextSlide+1)+') a').addClass('is-active')
        })

        $('#banner.home #slidernav li a:not(.is-arrow)').on('click', function () {
            const number = $(this).attr('data-number')
            $('#banner.home #slider .slides').slick('slickGoTo', number)
        })
    }

    if($('.solutions-slider').length){
        $(window).on('resize', function () {
            if(window.innerWidth > 638 && $('.solutions-slider .sliderwrap').is('.slick-slider')){
                $('.solutions-slider .sliderwrap').slick('unslick')
            } else if(window.innerWidth <= 638) {
                solutionSlider()
            }
        })

        if(window.innerWidth < 638){
            solutionSlider()
        }
    }

    if($('.softwaremodules').length){
        $('.softwaremodules').slick({
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            appendArrows: $('.softwaremodules-wrap .arrows'),
            prevArrow: '<i class="icon icon-arrow-left"></i>',
            nextArrow: '<i class="icon icon-arrow-right"></i>',

            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            }]
        })
    }
})
